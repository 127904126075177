import React from 'react'
import clsx from 'clsx'
import {
  firstSectionData,
  howItWorksSectionData,
} from '../../app/main/constants'
import healthHeroLogo from '../assets/images/HealthHeroLogo.svg'

export const MainPage = () => {
  return (
    <div className="generic-covid-testing-page">
      <main>
        <section className="title-content-wrapper">
          <div className="title-content-background-wrapper">
            <div className="title-content-text">
              <div className="healthHeroLogo-wrapper">
                <img src={healthHeroLogo} alt="HealthHeroLogo" className="healthHeroLogo-image"/>
              </div>
              <div className="inner-content">
                <div className="title-content-text-wrapper">
                  <div className="title-content-text-main-title flex flex-column font-title">
                    <h1><span className="white-color">Order a </span>Coronavirus antibody test</h1>
                  </div>
                  <div className="title-content-text-main-subtitle flex flex-column font-subtitle">
                    <span>
                      Fast and accurate tests with results in 3-5 days
                    </span>
                    <span>at a discounted rate of only £37</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="options-content-wrapper">
          <div className="inner-content">
            <div className="options-title font-subtitle">
              <span>
                Order a clinically validated, CE marked antibody test today and
                find out if you have been exposed to COVID-19 for only £37.
                Fulfilled by our trusted partner, Testing For All. The test is
                easy to self-administer from home and requires a 0.5ml finger
                prick blood sample, which is approximately 10-15 drops.
              </span>
            </div>
            <div className="options-items-wrapper flex flex-column">
              <div className="options-items">
                <ul className="options-content-list flex">
                  {firstSectionData.map((item) => (
                    <li
                      className={clsx(
                        'options-content-list-item flex flex-column',
                        item.imgAlt === 'results online'
                          ? 'results-online'
                          : '',
                      )}
                      key={item.title}
                    >
                      <div className="options-content-list-item-image-wrapper">
                        <img
                          src={item.imgSource}
                          alt={item.imgAlt}
                          className="options-content-list-item-image"
                        />
                      </div>
                      <span className="font-title options-content-list-item-title">
                        {item.title}
                      </span>
                      <span className="options-content-list-item-subtitle">
                        {item.subtitle}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="options-coupon-code-wrapper flex flex-center flex-column">
                <div className="options-coupon-code-title font-subtitle">
                  <span>
                    Enter the coupon code provided on the previous page at the checkout <span className="subtitle-color">to apply your discount</span>
                  </span>
                </div>
                <div className="options-coupon-code-links-wrapper">
                  <div className="options-coupon-code-links flex flex-column">
                    <div className="options-coupon-code-links-first">
                      <a
                        href="https://www.testingforall.org/product/covid-19-home-antibody-test/"
                        className="options-coupon-code-link"
                        target="_blank"
                      >
                        Order an antibody test
                      </a>
                      <a
                        href="https://www.testingforall.org/individuals/"
                        className="options-coupon-code-link transparent-link"
                        target="_blank"
                      >
                        Find out more
                      </a>
                    </div>
                    <span className="options-coupon-code-link-subtitle">
                      You will be taken to our partner site to register your
                      details
                    </span>
                  </div>
                </div>
                <div className="options-coupon-code-links-terms">
                  <span>
                    <a
                      href="https://www.testingforall.org/terms-of-service/"
                      className="options-coupon-code-terms-link"
                      target="_blank"
                    >
                      Terms and conditions apply
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="how-it-works-content-wrapper">
          <div className="inner-content">
            <div className="how-it-works-titles">
              <div className="how-it-works-title font-title">
                <span className="how-it-works-title-color">How it</span><span> works</span>
              </div>
              <div className="font-subtitle how-it-works-subtitle">
                <span>Fast and accurate tests from home</span>
              </div>
            </div>
            <div className="how-it-works-items">
              <ul className="how-it-works-list flex">
                {howItWorksSectionData.map((item) => (
                  <li className="how-it-works-item" key={item.title}>
                    <div className="how-it-works-item-img-wrapper">
                      <img
                        src={item.imgSrc}
                        alt={item.imgAlt}
                        className="how-it-works-item-img"
                      />
                    </div>
                    <span className="font-title how-it-works-item-title">
                      {item.title}
                    </span>
                    <span className="how-it-works-item-subtitle">
                      {item.subtitle}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="how-it-works-links flex">
              <div className="how-it-works-links-wrapper flex flex-column">
                <a
                  href="https://www.testingforall.org/product/covid-19-home-antibody-test/"
                  className="how-it-works-link"
                  target="_blank"
                >
                  Order an antibody test
                </a>
                <span className="how-it-works-link-subtitle">
                  You will be taken to our partner site to register your details
                </span>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}
